import React, { useRef } from 'react'

import { useHasHovered } from 'hooks/useHasHovered'

import { Container } from './DeletableContainer.styles'
import { FloatedDeleteButton } from './FloatedDeleteButton'

export interface DeletableContainerProps {
  'data-test'?: string
  children?: JSX.Element | JSX.Element[]
  className?: string
  onDeleteClick?: () => void
}

export const DeletableContainer: React.FC<DeletableContainerProps> = ({
  'data-test': dataTest = 'DeletableContainer',
  children,
  className,
  onDeleteClick,
}) => {
  const ref = useRef()
  const hasHovered = useHasHovered(ref, 500)

  return (
    <Container
      data-test={`${dataTest}.deletable-container`}
      ref={ref}
      className={className}
    >
      {children}
      {hasHovered && (
        <FloatedDeleteButton
          data-test={`${dataTest}.floated-delete-button`}
          onClick={onDeleteClick}
        />
      )}
    </Container>
  )
}
