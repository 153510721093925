import styled from 'styled-components'
import tw from 'twin.macro'

import Button from 'components/Button'

export const Wrapper = tw.div`
  relative
`
export const CurrencySwitcher = tw.div`
  absolute
  right-2
  top-0
  bottom-0
  flex
  items-center
  font-ringside-narrow
  font-weight[300]
  text-[12px]
  leading-[14px]
  text-light-500
`

export const RadioButton = styled(Button)<{ selected?: boolean }>`
  ${tw`
    text-light-500
    px-0.5
    py-[4px]
    border-radius[4px]
    font-ringside-narrow
    font-weight[300]
    text-[12px]
    leading-[14px]
  `}
  ${({ selected }) =>
    selected && tw` text-white focus:text-white focus:border-transparent`}
`

export const AmountButton = styled(Button)`
  ${tw`
    px-1
    py-0.5
    border-transparent
    font-ringside-narrow
    font-weight[300]
    text-[12px]
    leading-[14px]
    text-light-400
  `}
`

export const AmountToolbar = tw.div`
  w-full
  flex
  justify-between
  items-center
  bg-light-700
  border-radius[2px]
  p-1
  box-shadow[0px 4px 4px rgba(0, 0, 0, 0.25), 0px 41px 79px rgba(0, 0, 0, 0.3), 0px 19.5949px 23.8162px rgba(0, 0, 0, 0.195477), 0px 14.1236px 9.89203px rgba(0, 0, 0, 0.15)]
  backdrop-blur-[16px]
  z-10
`
export const ErrorContainer = tw.div`
  p-1
  text-ui-red
  font-ringside-regular
  font-weight[300]
  text-[10px]
  leading-[12px]
`
