import { useRecoilValue } from 'recoil'

import { TalosDecimal } from 'lib/decimal-util'
import store from 'store'
import { CurrencyPair } from 'types/currency-pair'

export const useAvailableBalance = (
  pair: CurrencyPair,
): [TalosDecimal, TalosDecimal] => {
  const balances = useRecoilValue(
    store.balance.balanceOfCurrencyPair(
      pair.map((c) => c.Symbol) as [TalosDecimal, TalosDecimal],
    ),
  )

  return balances.map((balance) => balance.availableAmount) as [
    TalosDecimal,
    TalosDecimal,
  ]
}
