import 'twin.macro'
import { isNil } from 'lodash'
import { useRef, useState, useMemo, useCallback } from 'react'

import { useDirection } from 'hooks/useDirection'
import { maybeNumber, splitDecimal } from 'lib/decimal-util'
import { OrderType } from 'types/order-type'
import { Side } from 'types/side'

import {
  Button,
  TypeButtonTitleBuy,
  TypeButtonTitleSell,
  BigMainNumber,
  SmallAncillaryNumber,
  TypeButtonTitle,
  PriceIndicatorContainer,
  TrendSymbolUpIcon,
  TrendSymbolDownIcon,
} from './PriceButton.styles'

export interface PriceButtonProps {
  'data-test': string
  side: Side
  orderType?: OrderType
  price: string
  onSelect?: () => void
  onConfirm?: () => void
  onBlur?: () => void
}

const SIGNIFICANT_NUMBERS = 6 // 5 numbers plus the point

export const PriceButton: React.FC<PriceButtonProps> = ({
  'data-test': dataTest = 'price-button',
  side,
  orderType,
  price,
  onSelect,
  onConfirm,
  onBlur,
}) => {
  const [confirmActive, setConfirmActive] = useState<boolean>(false)

  const buttonEl = useRef<HTMLButtonElement | null>(null)

  const typeButtonTitle = useMemo(() => {
    if (['limit', 'stop-limit', 'steady-pace'].includes(orderType)) {
      return side === 'buy' ? 'Place Buy Order' : 'Place Sell Order'
    }
    return side === 'buy' ? 'Buy' : 'Sell'
  }, [orderType, side])

  const confirmTypeButtonTitle = useMemo(() => {
    if (['limit', 'stop-limit', 'steady-pace'].includes(orderType)) {
      return side === 'buy' ? 'Buy Order' : 'Sell Order'
    }
    return side === 'buy' ? 'Buy' : 'Sell'
  }, [orderType, side])

  const maybePrice = useMemo(() => maybeNumber(price), [price])

  const [priceInteger, priceDecimal] = useMemo(
    () => splitDecimal(price) ?? [],
    [price],
  )

  const priceDirection = useDirection(maybePrice, 400)

  const handleClick = useCallback(() => {
    if (confirmActive) {
      onConfirm && onConfirm()
      if (buttonEl) buttonEl.current.blur()
    } else {
      onSelect && onSelect()
      setConfirmActive(true)
    }
  }, [confirmActive, onConfirm, onSelect])

  const handleBlur = useCallback(() => {
    setConfirmActive(false)
    onBlur && onBlur()
  }, [onBlur])

  const TypeButtonTitleBuySell = useMemo(
    () => (side === 'buy' ? TypeButtonTitleBuy : TypeButtonTitleSell),
    [side],
  )

  const [bigMainNumber, lowerSmallAncillaryNumber, upperSmallAncillaryNumber] =
    useMemo(() => {
      if (isNil(maybePrice)) return ['--', '', '']

      if (priceInteger.length >= 2) {
        return [priceInteger, `.${priceDecimal}`]
      }

      if (price.length === 6) return [price, '', '']

      const [fistPartNumber, secondPartNumber] = [
        price.substring(0, SIGNIFICANT_NUMBERS),
        price.substring(SIGNIFICANT_NUMBERS, price.length),
      ]

      if (maybeNumber(fistPartNumber) === 0)
        return [secondPartNumber, '', fistPartNumber]

      return [fistPartNumber, secondPartNumber, '']
    }, [maybePrice, price, priceDecimal, priceInteger])

  return (
    <Button
      data-test={`${dataTest}.element`}
      side={side}
      className="group"
      aria-label={side}
      ref={buttonEl}
      onBlur={handleBlur}
      onClick={handleClick}
    >
      <PriceIndicatorContainer>
        <TrendSymbolUpIcon show={priceDirection === 'up' ? 1 : 0} />
        {confirmActive ? (
          <TypeButtonTitle data-test={`${dataTest}.title`}>
            Confirm {confirmTypeButtonTitle}
          </TypeButtonTitle>
        ) : (
          <TypeButtonTitleBuySell data-test={`${dataTest}.tx-title`}>
            {typeButtonTitle}
          </TypeButtonTitleBuySell>
        )}
        <TrendSymbolDownIcon show={priceDirection === 'down' ? 1 : 0} />
      </PriceIndicatorContainer>

      <SmallAncillaryNumber
        data-test={`${dataTest}.upper-small-ancillary-number`}
        confirm={confirmActive}
      >
        {upperSmallAncillaryNumber}
      </SmallAncillaryNumber>
      <BigMainNumber data-test={`${dataTest}.big-main-number`}>
        {bigMainNumber}
      </BigMainNumber>
      <SmallAncillaryNumber
        data-test={`${dataTest}.lower-small-ancillary-number`}
        confirm={confirmActive}
      >
        {lowerSmallAncillaryNumber}
      </SmallAncillaryNumber>
    </Button>
  )
}
