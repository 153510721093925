import tw from 'twin.macro'

import Warning from 'assets/icons/alert-octagon.svg'
import Success from 'assets/icons/check-circle.svg'
import Error from 'assets/icons/error.svg'
import Question from 'assets/icons/help-circle.svg'
import Delete from 'assets/icons/trash.svg'

export const Wrapper = tw.div`
    w-[225px]
    min-h-[65px]
    flex
    justify-center
    items-center
    p-[16px]
    gap-[16px]
    bg-[rgba(255, 255, 255, 0.08)]
    border-radius[4px]
    box-shadow[0px 4px 4px rgba(0, 0, 0, 0.25), 0px 41px 79px rgba(0, 0, 0, 0.3), 0px 19.5949px 23.8162px rgba(0, 0, 0, 0.195477), 0px 14.1236px 9.89203px rgba(0, 0, 0, 0.15)]
    backdrop-filter[blur(64px)]
`

export const InfoContainer = tw.div`flex-1 `

export const Title = tw.div`
    font-ringside-narrow
    font-weight[350]
    text-[14px]
    leading-[16px]
    text-white
`

export const Message = tw.div`
    w-full
    font-ringside-narrow
    font-weight[300]
    text-[12px]
    leading-[14px]
    text-white
    opacity-50
    whitespace-normal
    word-break[normal]
`

export const WarningIcon = tw(Warning)`w-[24px] fill-yellow`

export const SuccessIcon = tw(Success)`w-[24px] fill-ui-green`

export const ErrorIcon = tw(Error)`w-[24px] fill-ui-red`

export const QuestionIcon = tw(Question)`w-[24px] stroke-white fill-transparent`

export const DeleteIcon = tw(Delete)`w-[24px] fill-ui-red`
