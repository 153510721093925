import styled from 'styled-components'
import tw from 'twin.macro'

import TrendSymbolDown from 'assets/icons/trend-symbol-down.svg'
import TrendSymbolUp from 'assets/icons/trend-symbol-up.svg'
import { Side } from 'types/side'

export const Button = styled.button<{
  side: Side
}>(({ side }) => [
  tw`
      pt-[3px]
      px-2.5
      min-w-[144px]
      h-[85px]
      flex
      flex-col
      justify-start
      items-center
      text-light-200
      border-radius[4px]
      hover:bg-light-900
      hover:text-white
  `,
  side === 'buy' && tw`focus:bg-ui-green`,
  side === 'sell' && tw`focus:bg-ui-red`,
])

export const TypeButtonTitle = styled.span`
  ${tw`
    font-ringside-regular
    font-weight[300]
    text-[10px]
    leading-[14px]
    text-light-600
    group-focus:text-white
  `}
`

export const TypeButtonTitleBuy = styled(TypeButtonTitle)`
  ${tw`text-light-600 group-hover:text-ui-green`}
`

export const TypeButtonTitleSell = styled(TypeButtonTitle)`
  ${tw`text-light-600 group-hover:text-ui-red`}
`

export const BigMainNumber = tw.span`
    font-ringside-condensed
    font-weight[300]
    text-[36px]
    leading-[35px]
    color[gba(255, 255, 255, 0.5)]
`
export const SmallAncillaryNumber = styled.span<{ confirm: boolean }>`
  ${tw`
    font-ringside-narrow
    font-weight[300]
    text-[12px]
    leading-[20px]
    text-light-400
    group-hover:text-white
  `}

  ${({ confirm }) =>
    confirm ? tw`group-hover:text-light-200 text-light-200` : ''}
`

export const TrendSymbolUpIcon = styled(TrendSymbolUp)<{
  show: boolean
}>`
  ${({ show }) => (show ? tw`opacity-100` : tw`opacity-0`)}
  ${tw`w-[10px] stroke-light-500 mb-[-2px]`}
`

export const TrendSymbolDownIcon = styled(TrendSymbolDown)<{ show: boolean }>`
  ${({ show }) => (show ? tw`opacity-100` : tw`opacity-0`)}
  ${tw`w-[10px] stroke-light-500 mt-[-2px]`}
`

export const PriceIndicatorContainer = tw.div`flex items-center justify-center gap-[3px]`
