import { useEffect, useRef, useState } from 'react'

import { Direction } from 'types/direction'

export const useDirection = (value: number, timeout = 200): Direction => {
  const valueRef = useRef<number>(value)

  const [direction, setDirection] = useState<Direction | null>(null)

  useEffect(() => {
    // If there's no change, only reset (this prevents to set a direction on first render).
    if (valueRef.current === value) {
      setDirection(null)

      // eslint-disable-next-line @typescript-eslint/no-empty-function
      return () => {}
    }

    // Set the direction.
    setDirection(value > valueRef.current ? 'up' : 'down')

    // Reset the direction state after `timeout`.
    const timeoutInterval = setTimeout(() => {
      setDirection(null)
    }, timeout)

    // Update the ref to reflect the new `value`.
    valueRef.current = value

    return () => {
      clearTimeout(timeoutInterval)
    }
  }, [timeout, value])

  return direction
}
