import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useHoverDirty, useUpdateEffect } from 'react-use'

export const useHasHovered = (
  ref: React.MutableRefObject<HTMLElement>,
  duration: number,
): boolean => {
  const [hasHovered, setHasHovered] = useState(false)
  const timerRef = useRef(null)
  const isHovered = useHoverDirty(ref)

  const enter = useCallback(() => {
    timerRef.current = setTimeout(() => setHasHovered(true), duration)
  }, [duration, timerRef, setHasHovered])

  const leave = useCallback(() => {
    clearTimeout(timerRef.current)
    timerRef.current = null
    setHasHovered(false)
  }, [timerRef, setHasHovered])

  useUpdateEffect(() => {
    if (isHovered) {
      enter()
    } else {
      leave()
    }
  }, [isHovered, enter, leave])

  // on-dismount
  useEffect(
    () => () => {
      if (timerRef.current) {
        clearTimeout(timerRef.current)
      }
    },
    [timerRef],
  )

  return hasHovered
}
