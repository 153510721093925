import { useSocket } from 'hooks/useSocket'
import { useSubscription } from 'hooks/useSubscription'
import { BalanceResponse } from 'types/api/balance'

import { BALANCE_SUBSCRIPTION_MESSAGE } from './consts'
import { useBalanceReducer } from './useBalanceReducer'

export const useBalanceSubscription = () => {
  const socket = useSocket()

  const balances = useSubscription<BalanceResponse>(
    socket,
    BALANCE_SUBSCRIPTION_MESSAGE,
  )

  useBalanceReducer(balances)
}
