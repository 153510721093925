import { useRecoilCallback } from 'recoil'

import store from 'store'
import { BalanceResponse } from 'types/api/balance'

export const useBalanceReducer = (balanceResponses: BalanceResponse[]) => {
  const updateBalance = useRecoilCallback(
    ({ set }) =>
      async (newBalances: BalanceResponse[]) => {
        if (newBalances.length === 0) return
        for (const newBalance of newBalances) {
          set(store.balance.balances(newBalance.Currency), {
            amount: newBalance.Amount,
            availableAmount: newBalance.AvailableAmount,
          })
        }
      },
  )

  updateBalance(balanceResponses)
}
