import { useCallback } from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import store from 'store'
import { CurrencyPair } from 'types/currency-pair'
import { StreamingTileLocalSliver } from 'types/streaming-tile-local-sliver'

export const useRemoveTile = () => {
  const viewState = useRecoilValue(store.view.selectedView)
  const currentGroupId = useRecoilValue(store.tileSlice.selectedGroupId)
  const setCurrentGroup = useSetRecoilState(
    store.tileSlice.groupById(currentGroupId),
  )
  const setTileBeingDragged = useSetRecoilState(
    store.tileSlice.tileBeingDragged,
  )

  const removeTile = useCallback(
    (position: number) => {
      if (position < 0) return

      if (viewState === 'trading') {
        setCurrentGroup(
          (prev) =>
            [
              ...prev.slice(0, position),
              ...prev.slice(position + 1),
            ] as StreamingTileLocalSliver[],
        )
      } else if (viewState === 'rfq') {
        setCurrentGroup(
          (prev) =>
            [
              ...prev.slice(0, position),
              ...prev.slice(position + 1),
            ] as CurrencyPair[],
        )
      }

      setTileBeingDragged(-1)
    },
    [setTileBeingDragged, setCurrentGroup, viewState],
  )

  return removeTile
}
