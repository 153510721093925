import React from 'react'

import { StyledXIcon, FloatedButton } from './DeletableContainer.styles'

export interface FloatedDeleteButtonProps {
  'data-test': string
  onClick?: () => void
}

export const FloatedDeleteButton: React.FC<FloatedDeleteButtonProps> = ({
  'data-test': dataTest = 'floated-delete-button',
  onClick,
}) => {
  return (
    <FloatedButton data-test={`${dataTest}.floated-button`} onClick={onClick}>
      <StyledXIcon />
    </FloatedButton>
  )
}
