import tw from 'twin.macro'

import XIcon from 'assets/icons/x.svg'
import Button from 'components/Button'

export const Container = tw.div`relative`

export const StyledXIcon = tw(XIcon)`h-[7px] w-[7px] fill-white`

export const FloatedButton = tw(Button)`
  absolute
  top-[-7px]
  left-[-7px]
  h-[16px]
  w-[16px]
  p-0
  min-h-0
  flex
  items-center
  justify-center
  bg-white/[.32]
  hover:bg-white/[.32]
`
