import { useTimeout } from 'hooks/useTimeout'
import { NotificationData, NotificationType } from 'types/notification'

import {
  Wrapper,
  InfoContainer,
  Title,
  Message,
  SuccessIcon,
  DeleteIcon,
  ErrorIcon,
  QuestionIcon,
  WarningIcon,
} from './Notification.styles'

const Icon: React.FC<{ dataTest: string; type: NotificationType }> = ({
  dataTest,
  type,
}) =>
  'success' === type ? (
    <SuccessIcon data-test={`${dataTest}.icon-success`} />
  ) : 'warning' === type ? (
    <WarningIcon data-test={`${dataTest}.icon-warning`} />
  ) : 'error' === type ? (
    <ErrorIcon data-test={`${dataTest}.icon-error`} />
  ) : 'question' === type ? (
    <QuestionIcon data-test={`${dataTest}.icon-question`} />
  ) : 'delete' === type ? (
    <DeleteIcon data-test={`${dataTest}.icon-delete`} />
  ) : null

export interface NotificationProps extends NotificationData {
  'data-test': string
  type: 'success' | 'warning' | 'error' | 'question' | 'delete'
  title: string
  message: string
  close: () => void
}

export const Notification: React.FC<NotificationProps> = ({
  'data-test': dataTest = 'notification',
  type,
  title,
  message,
  close,
}) => {
  useTimeout(close, 3000)

  return (
    <Wrapper data-test={`${dataTest}.container`} onClick={close}>
      <Icon dataTest={dataTest} type={type} />
      <InfoContainer>
        <Title data-test={`${dataTest}.title`}>{title}</Title>
        <Message data-test={`${dataTest}.message`}>{message}</Message>
      </InfoContainer>
    </Wrapper>
  )
}
