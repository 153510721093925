import { useEffect, useState } from 'react'
import { useRecoilValue } from 'recoil'

import store from 'store'
import { NotificationData } from 'types/notification'
import { OrderStatus } from 'types/order-status'

export const useOrderStatus = (
  uuid: string,
  add: (NotificationData) => void,
) => {
  const [notified, setNotified] = useState(false)
  const [status, setStatus] = useState<OrderStatus | ''>('')
  const order = useRecoilValue(store.trade.orderReponseSelector(uuid))

  useEffect(() => {
    setNotified(false)
    setStatus('')
  }, [uuid])

  useEffect(() => {
    if (order.OrdStatus === 'New' && !notified) {
      add({
        title: 'Trade Executed',
        type: 'success',
      } as NotificationData)
      setNotified(true)
    }
  }, [order, status, add, setNotified, notified])
}
