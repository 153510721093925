import 'twin.macro'

import Notification from 'components/Notification'
import Popover from 'components/Popover'
import { NotificationDataWithID } from 'types/notification'

export interface NotificationListProps {
  'data-test': string
  notifications: NotificationDataWithID[]
  referenceElement: HTMLElement | null
  onCloseNotification: (id: string) => void
}

export const NotificationList: React.FC<NotificationListProps> = ({
  'data-test': dataTest = 'notification-list',
  notifications,
  referenceElement,
  onCloseNotification,
}) => {
  return !!referenceElement && notifications.length > 0 ? (
    <Popover
      data-test={`${dataTest}.popover`}
      referenceElement={referenceElement}
      placement="bottom"
      distance={7}
    >
      <div
        data-test={`${dataTest}.wrapper`}
        className="notifications-container"
        tw="flex flex-col gap-[7px]"
      >
        {notifications.map((notification) => (
          <Notification
            data-test={`${dataTest}.notification`}
            data-test-arg={notification}
            key={notification.id}
            message={notification.message}
            title={notification.title}
            type={notification.type}
            close={() => onCloseNotification(notification.id)}
          />
        ))}
      </div>
    </Popover>
  ) : null
}
