import { isNil } from 'lodash'
import { useCallback } from 'react'

import { maybeNumber } from 'lib/decimal-util'
import { Currency } from 'types/currency'
import { CurrencyPair } from 'types/currency-pair'

export const useHandleTradeAmount = (
  currencyPair: CurrencyPair,
  selectedCurrency: Currency,
  baseCurrencyAvailable: string,
  quotedCurrencyAvailable: string,
  setTradeAmount: (value: string) => void,
  setTradeAmountError: (value: string) => void,
) => {
  const getAvailableCurrency = useCallback(() => {
    const index = currencyPair.findIndex(
      (pair) => pair.Symbol === selectedCurrency.Symbol,
    )
    const funds = parseFloat(
      [baseCurrencyAvailable, quotedCurrencyAvailable][index],
    )
    return funds ?? 0
  }, [
    selectedCurrency,
    currencyPair,
    baseCurrencyAvailable,
    quotedCurrencyAvailable,
  ])

  const handleAmountChange = useCallback(
    (change: string) => {
      const parsed = maybeNumber(change)

      if (isNil(parsed)) {
        setTradeAmount('')
        setTradeAmountError('Please enter a valid number')
      } else {
        setTradeAmount(change)
        if (parsed > getAvailableCurrency()) {
          setTradeAmountError('Not enough funds')
        } else setTradeAmountError('')
      }
    },
    [setTradeAmount, setTradeAmountError, getAvailableCurrency],
  )

  return handleAmountChange
}
